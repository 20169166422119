import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useFormStore } from "@/core/form/store/FormStore"
import GeneratedImage from "@/media/generate/GeneratedImage"
import GenerateImageForm from "@/media/generate/GenerateImageForm"
import {
  GenerateAiImageInput,
  GenerateAiImageStyle,
  GenerateImageViewMutation,
} from "@/media/generate/__generated__/GenerateImageViewMutation.graphql"
import SparklesIcon from "@assets/images/empty-state-illustrations/sparkles.svg"
import { ImageCropModalCropperProps } from "@components/image/crop-modal/util/imageCropModalTypes"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoEmptyState } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { graphql } from "react-relay"

interface Props {
  onMediaSelect: (result: MediaResult) => void
  onClose: () => void
  cropperProps?: ImageCropModalCropperProps
}

function GenerateImageView(props: Props) {
  const { onMediaSelect, onClose, cropperProps } = props
  const [aiImage, setAiImage] = useState("")
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()

  const form = useFormStore<GenerateImageViewMutation, GenerateAiImageInput>(
    graphql`
      mutation GenerateImageViewMutation($input: GenerateAiImageInput!) {
        response: generateAiImageInMediaPicker(input: $input) {
          data
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      productId: activeProduct?.id,
      style: "vivid" as GenerateAiImageStyle,
      prompt: "",
    }
  )

  return (
    <>
      <GenerateImageForm form={form} handleSubmit={handleSubmit} />

      {aiImage ? (
        <GeneratedImage
          image={aiImage}
          onMediaSelect={onMediaSelect}
          handleSubmit={handleSubmit}
          cropperProps={cropperProps}
          onClose={onClose}
        />
      ) : (
        <DiscoEmptyState
          testid={"GenerateImageView.empty-state"}
          icon={<SparklesIcon width={150} height={150} />}
          title={
            form.isSubmitting
              ? "Thinking 🧠"
              : "Describe the image above and watch the Disco AI work it's magic 🪄"
          }
          subtitle={
            form.isSubmitting
              ? "This can take up to 60 seconds, please be patient ... it is worth it!"
              : ""
          }
        />
      )}
    </>
  )

  async function handleSubmit() {
    setAiImage("")
    const { didSave, response } = await form.submit(form.state)
    if (!didSave || !response || !response.data) return

    displaySuccessToast({
      message: "Image Generated",
      testid: "GenerateImageView.success-toast",
    })
    setAiImage(response.data)
  }
}

export default observer(GenerateImageView)
