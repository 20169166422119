/**
 * @generated SignedSource<<b9380910f60eef4e1e11694f8cf83e93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductRegistrationAvailability = "public" | "private" | "hidden" | "%future added value";
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateInvitationFormFragment$data = {
  readonly id: string;
  readonly slug: string;
  readonly name: string;
  readonly type: ProductType;
  readonly status: ProductStatus;
  readonly registrationAvailability: ProductRegistrationAvailability;
  readonly tmpAllowExternalRegistrations: boolean;
  readonly __typename: "Product";
  readonly " $fragmentSpreads": FragmentRefs<"InvitationFormCopyLinkField__Product" | "InvitationFormEmailsField__Product" | "InvitationFormCSVPreview_productFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "CreateInvitationFormFragment";
};
export type CreateInvitationFormFragment$key = {
  readonly " $data"?: CreateInvitationFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateInvitationFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateInvitationFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tmpAllowExternalRegistrations",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvitationFormCopyLinkField__Product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvitationFormEmailsField__Product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvitationFormCSVPreview_productFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "2ecdb04c8f6f35c899fdae12ada6f6ea";

export default node;
