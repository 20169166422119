/**
 * @generated SignedSource<<ed16a589c662016fc7f1b34df84e8cc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvitationFormEmailsField__Product$data = {
  readonly id: string;
  readonly slug: string;
  readonly type: ProductType;
  readonly status: ProductStatus;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationFormMemberFieldsFragment" | "InvitationFormRoleSelectFragment">;
  readonly " $fragmentType": "InvitationFormEmailsField__Product";
};
export type InvitationFormEmailsField__Product$key = {
  readonly " $data"?: InvitationFormEmailsField__Product$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationFormEmailsField__Product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitationFormEmailsField__Product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvitationFormMemberFieldsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvitationFormRoleSelectFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "39e763c2c562c84a0bfe475e430c28b3";

export default node;
