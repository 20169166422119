/**
 * @generated SignedSource<<0d9c110feb5d1525c53385e7a362ee30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvitationFormRoleSelectFragment$data = {
  readonly id: string;
  readonly type: ProductType;
  readonly status: ProductStatus;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  readonly " $fragmentType": "InvitationFormRoleSelectFragment";
};
export type InvitationFormRoleSelectFragment$key = {
  readonly " $data"?: InvitationFormRoleSelectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvitationFormRoleSelectFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvitationFormRoleSelectFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "6cced0f446526087c0a651472d49c323";

export default node;
