import Badge from "@/admin/experiences/badges/Badge"
import { APP_REMOVABLE_KINDS } from "@/apps/util/appConstants"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { CHANNEL_ROUTE_PREFIX, CHAT_ROUTE_PREFIX } from "@/core/route/util/routeNames"
import InfoIcon from "@/core/ui/iconsax/linear/custom-info.svg"
import ExperienceAdminDropdown from "@/experience/admin/dropdown/ExperienceAdminDropdown"
import { PageHeaderPortal } from "@/main/page/header/AppHeaderLayout"
import { HeaderContentFragment$key } from "@/main/page/header/__generated__/HeaderContentFragment.graphql"
import { HeaderContent_ProductAppsFragment$key } from "@/main/page/header/__generated__/HeaderContent_ProductAppsFragment.graphql"
import generateCoursePaths from "@/organization/common/sidebar/my-experiences-list/util/generateCoursePaths"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoIcon,
  DiscoIconButton,
  DiscoIconKinds,
  DiscoLink,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"
import { useLocation } from "react-router-dom"

export interface HeaderContentProps extends TestIDProps {
  leftIcon?: React.ReactNode | DiscoIconKinds
  appKey?: HeaderContentFragment$key | null
  rightOptions?: React.ReactNode
  centerOptions?: React.ReactNode
  title?: string | React.ReactNode
  descriptionTooltip?: string | React.ReactNode
  rightTitleContent?: React.ReactNode
  className?: string
  rightOverflow?: React.ReactNode
  additionalContent?: React.ReactNode
}

function HeaderContent(props: HeaderContentProps) {
  const activeProduct = useActiveProduct()
  const permissions = useActiveProductOrOrganizationPermissions()
  const location = useLocation()
  const {
    appKey = null,
    title,
    descriptionTooltip,
    rightTitleContent,
    rightOptions,
    centerOptions,
    leftIcon,
    className,
    testid = "HeaderContent",
    rightOverflow,
    additionalContent,
  } = props
  const classes = useStyles()
  const isMobile = useIsMobile()

  const product = useFragment<HeaderContent_ProductAppsFragment$key>(
    graphql`
      fragment HeaderContent_ProductAppsFragment on Product {
        id
        ...ExperienceAdminDropdownFragment
        badge {
          ...BadgeFragment
        }
      }
    `,
    activeProduct
  )

  const app = useFragment<HeaderContentFragment$key>(
    graphql`
      fragment HeaderContentFragment on ProductApp {
        id
        kind
        customAppTitle
        badge {
          ...BadgeFragment
        }
        ...RemoveAppButtonFragment
      }
    `,
    appKey
  )
  const isChannelOrChat =
    location.pathname.includes(CHANNEL_ROUTE_PREFIX) ||
    location.pathname.includes(CHAT_ROUTE_PREFIX)
  const COURSE_PATHS = activeProduct ? generateCoursePaths(activeProduct.slug) : null

  const isAppRemovable =
    permissions.has("apps.manage") &&
    app &&
    // We don't use APP_REMOVABLE_KINDS to handle channels/chats here since it will use the default delete app modal
    (APP_REMOVABLE_KINDS.has(app.kind) || isChannelOrChat)
  const hasRightPortalContent = rightOptions || isAppRemovable || rightOverflow

  return (
    <>
      <PageHeaderPortal position={"left"}>
        <Grid
          container
          data-testid={`${testid}.left-portal`}
          className={classNames(classes.titleContainer, className)}
        >
          {!isMobile && renderLeftIcon()}
          {renderTitle()}
          {rightTitleContent && (
            <Grid item xs>
              {rightTitleContent}
            </Grid>
          )}
        </Grid>
      </PageHeaderPortal>
      {centerOptions && (
        <PageHeaderPortal position={"center"}>
          <div data-testid={`${testid}.center-portal`} className={classes.centerOptions}>
            {centerOptions}
          </div>
        </PageHeaderPortal>
      )}
      {hasRightPortalContent && (
        <PageHeaderPortal position={"right"}>
          <div data-testid={`${testid}.right-portal`} className={classes.rightOptions}>
            {rightOptions}
            {rightOverflow ||
              (product && !isChannelOrChat && (
                <ExperienceAdminDropdown
                  testid={`${testid}.more-actions`}
                  productKey={product}
                  rotateIcon
                  moreActionsButtonClassName={classes.moreActions}
                />
              ))}
          </div>
        </PageHeaderPortal>
      )}
      {additionalContent && (
        <PageHeaderPortal position={"bottom"}>{additionalContent}</PageHeaderPortal>
      )}
    </>
  )

  function renderTitle() {
    const productName = activeProduct?.name

    const tooltip = descriptionTooltip && (
      <DiscoTooltip
        data-testid={`${testid}.description.tooltip`}
        className={classes.descriptionTooltip}
        content={
          <DiscoText
            testid={`${testid}.description.tooltip-text`}
            variant={"body-md-600"}
          >
            {descriptionTooltip}
          </DiscoText>
        }
      >
        <DiscoIconButton classes={{ root: classes.descriptionTooltipButton }}>
          <InfoIcon />
        </DiscoIconButton>
      </DiscoTooltip>
    )

    if (productName) {
      if (isMobile) {
        return (
          <DiscoText
            data-testid={`${testid}.title`}
            variant={"body-md-500"}
            truncateText={1}
          >
            {title || "Dashboard"}
          </DiscoText>
        )
      }
      const appTitle = app?.customAppTitle || title
      return (
        <div className={classes.title}>
          <div className={classes.channelTitleContainer}>
            <DiscoText
              variant={"body-md-500"}
              data-testid={`${testid}.title`}
              truncateText={1}
            >
              {appTitle || productName}
            </DiscoText>

            {appTitle && (
              <DiscoText variant={"body-sm"} truncateText={1} component={"span"} noWrap>
                {"in "}
                <DiscoLink to={{ pathname: COURSE_PATHS?.DASHBOARD }}>
                  <DiscoText
                    variant={"body-sm"}
                    color={"primary.main"}
                    display={"inline"}
                  >
                    {productName}
                  </DiscoText>
                </DiscoLink>
              </DiscoText>
            )}
          </div>

          {tooltip}
        </div>
      )
    }
    if (title) {
      if (typeof title === "string") {
        return (
          <div className={classes.title}>
            <DiscoText
              variant={"body-md-500"}
              data-testid={`${testid}.title`}
              truncateText={1}
            >
              {title}
            </DiscoText>

            {tooltip}
          </div>
        )
      }
      return (
        <div className={classes.title}>
          {title}
          {tooltip}
        </div>
      )
    }
    return null
  }

  function renderLeftIcon() {
    if (leftIcon) {
      if (typeof leftIcon === "string") {
        return (
          <DiscoIcon
            icon={leftIcon as DiscoIconKinds}
            width={40}
            height={40}
            padding={1}
            active
            className={classes.icon}
          />
        )
      }
      return leftIcon
    }

    if (app?.badge) return <Badge badgeKey={app.badge} size={40} />
    if (product?.badge) return <Badge badgeKey={product.badge} size={40} />
    return null
  }
}

const useStyles = makeUseStyles((theme) => ({
  titleContainer: {
    gap: theme.spacing(1),
    alignItems: "center",
    overflowWrap: "anywhere",
    paddingRight: theme.spacing(1),
    flexWrap: "nowrap",
  },
  channelTitleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: 0,
  },
  centerOptions: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "center",
  },
  rightOptions: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
  },
  icon: {
    borderRadius: theme.measure.borderRadius.medium,
  },
  moreActions: {
    padding: 0,
    width: "48px",
    height: "48px",
    marginRight: theme.spacing(-1),
    marginLeft: theme.spacing(-0.5),
  },
  title: {
    display: "flex",
    alignItems: "center",
    minWidth: 0,
  },
  descriptionTooltip: {
    marginLeft: theme.spacing(1),
    height: "24px",
  },
  descriptionTooltipButton: {
    padding: 0,
    "& svg": {
      color: theme.palette.constants.icon,
    },
  },
}))

type SkeletonProps = Omit<HeaderContentProps, "icon" | "title"> & {
  showTitle?: boolean
  showIcon?: boolean
}

export const HeaderContentSkeleton: React.FC<SkeletonProps> = ({
  className,
  centerOptions,
  rightOptions,
  showTitle = true,
  showIcon = true,
}: SkeletonProps) => {
  const classes = useStyles()

  return (
    <div className={classNames(className, classes.titleContainer)}>
      {showIcon && <Skeleton variant={"circle"} width={24} height={24} />}
      {showTitle && <DiscoTextSkeleton width={"50%"} />}
      {centerOptions && <div className={classes.centerOptions}>{centerOptions}</div>}
      {rightOptions && <div className={classes.rightOptions}>{rightOptions}</div>}
    </div>
  )
}

export default HeaderContent
