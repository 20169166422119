import { invalidateRoleAvatarStackProductRoles } from "@/admin/members/roles/RoleAvatarStack"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import InvitationFormCopyLinkField from "@/invitation/create/form/fields/InvitationFormCopyLinkField"
import InvitationFormEmailsField from "@/invitation/create/form/fields/InvitationFormEmailsField"
import InvitationFormImportCSVField from "@/invitation/create/form/fields/InvitationFormImportCSVField"
import InvitationFormInviteLinkField from "@/invitation/create/form/fields/InvitationFormInviteLinkField"
import {
  CreateInvitationFormCSVValidationMutation,
  CreateInvitationFormCSVValidationMutation$data,
} from "@/invitation/create/form/__generated__/CreateInvitationFormCSVValidationMutation.graphql"
import { CreateInvitationFormFragment$key } from "@/invitation/create/form/__generated__/CreateInvitationFormFragment.graphql"
import {
  CreateInvitationFormMutation,
  CreateInvitationInput,
  OrganizationRole,
  ProductRole,
} from "@/invitation/create/form/__generated__/CreateInvitationFormMutation.graphql"
import InvitationUtils from "@/invitation/utils/InvitationUtils"
import PublishExperienceButton from "@/product/buttons/PublishProduct/PublishExperienceButton"
import { useProductLabel } from "@/product/util/hook/useProductLabel"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { useOrganizationRoleLabels, useProductRoleLabels } from "@/role/roleUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoAlert,
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoInputSkeleton,
  DiscoLink,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoQueryParamTabs, {
  useQueryParamState,
} from "@disco-ui/tabs/DiscoQueryParamTabs"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { ArrayUtils } from "@utils/array/arrayUtils"
import useHasEntitlement from "@utils/hook/useHasEntitlement"
import usePermissions from "@utils/hook/usePermissions"
import { wordStartsWithVowel } from "@utils/string/stringUtils"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect, useState } from "react"
import { useFragment } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"
import InvitationFormImportCSVBlockedField from "./fields/InvitationFormImportCSVBlockedField"

export type CreateInvitationFormQueryParams = {
  InviteTab?: "email" | "csv" | "link" | "invite-link"
}

interface Props {
  onCancel: VoidFunction
  onSubmit: VoidFunction
  productId: GlobalID | null
  testid?: string
  onlyAdminRoles?: boolean
  defaultProductRole?: ProductRole
  givenEmails?: string[]
  productKey?: CreateInvitationFormFragment$key | null
}

export type CreateInvitationFormState = CreateInvitationInput & {
  emails: string[]
  userIds: GlobalID[]
  csvUrl: string
  csvMetadata?: {
    data?: CreateInvitationFormCSVValidationMutation$data["validateCSV"]["rows"]
    file?: File
  }
  csvStep: "default" | "preview"
  inviteToExperience: boolean
}

function CreateInvitationForm(props: Props) {
  const {
    onCancel,
    onSubmit,
    productId,
    testid = "CreateInvitationForm",
    onlyAdminRoles,
    defaultProductRole,
    givenEmails,
    productKey = null,
  } = props
  const activeOrganization = useActiveOrganization()!

  const { authUser } = useAuthUser({ required: true })

  const organizationRoleLabels = useOrganizationRoleLabels()
  const productRoleLabels = useProductRoleLabels()
  const classes = useStyles()
  const [isValidatingCsv, setIsValidatingCsv] = useState(false)

  const node = useFragment<CreateInvitationFormFragment$key>(
    graphql`
      fragment CreateInvitationFormFragment on Product {
        __typename
        id
        slug
        name
        type
        status
        registrationAvailability
        tmpAllowExternalRegistrations
        ...InvitationFormCopyLinkField__Product
        ...InvitationFormEmailsField__Product
        ...InvitationFormCSVPreview_productFragment
        ...usePermissionsFragment
      }
    `,
    productKey
  )
  const product = Relay.narrowNodeType(node ?? null, "Product")
  const canPublish = usePermissions(product).has("product_status.manage")
  const isDraftProduct = product?.status === "draft"
  const showInviteLink = !productId && activeOrganization.visibility === "private"
  const showCopyLink = product
    ? product.tmpAllowExternalRegistrations ||
      product.registrationAvailability !== "hidden"
    : activeOrganization.visibility === "public"
  const isPathway = product?.type === "pathway"
  const productLabelSingular = useProductLabel(product?.type ?? "course").singular

  const [queryParamState, setQueryParamState] =
    useQueryParamState<CreateInvitationFormQueryParams>()

  useEffect(() => {
    if (queryParamState.InviteTab) return

    const defaultTab = showInviteLink
      ? "invite-link"
      : showCopyLink && !(isDraftProduct || onlyAdminRoles) // Ensure we show the email tab if it is in draft or only admin role change
      ? "link"
      : "email"

    // set the default query param state if one hasn't been passed
    // so we render the correct tab if the state of some of the conditions that determine the default tab change
    setQueryParamState({ InviteTab: defaultTab })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Validation for the invitation CSV file
  const validateCSV =
    Relay.useAsyncMutation<CreateInvitationFormCSVValidationMutation>(graphql`
      mutation CreateInvitationFormCSVValidationMutation(
        $csvUrl: String!
        $productId: ID
      ) {
        validateCSV(csvUrl: $csvUrl, productId: $productId) {
          rows {
            email {
              value
              error
            }
            firstName {
              value
              error
            }
            lastName {
              value
              error
            }
            group {
              value
              error
            }
          }
          totalRows
          validRows
          errors {
            field
            message
          }
        }
      }
    `)

  const form = useFormStore<CreateInvitationFormMutation, CreateInvitationFormState>(
    graphql`
      mutation CreateInvitationFormMutation(
        $input: CreateInvitationInput!
        $connections: [ID!]!
      ) {
        response: createInvitations(input: $input) {
          data {
            edges {
              node
                @appendNode(
                  connections: $connections
                  edgeTypeName: "InvitationNodeEdge"
                ) {
                id
                ...ProductInvitationReportTableRow_Invitation
                ...CommunityInvitationReportTableRow_Invitation
                organization {
                  invitesCount
                }
                productMembership {
                  id
                  ...ExperienceAdminsListItemFragment
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      productId,
      organizationId: activeOrganization.id,
      message: getDefaultMessage("member", "member"),
      emails: [],
      userIds: [],
      productRole: getDefaultProductRole(),
      organizationRole: getDefaultOrganizationRole(),
      csvUrl: "",
      csvMetadata: {},
      csvStep: "default",
      skipsApplication: false,
      discountId: null,
      inviteToProductIds: [],
      inviteToExperience: false,
    }
  )
  const hasBulkInviteEntitlement = useHasEntitlement("bulk_invite")

  // Roles other than member can only be invited from the Email tab
  useEffect(() => {
    if (queryParamState.InviteTab === "email") return
    if (form.state.organizationRole) {
      form.state.organizationRole = "member"
    } else {
      form.state.productRole = "member"
    }
  }, [queryParamState.InviteTab, form.state])

  // Update the invitation message when switching role
  useEffect(() => {
    form.state.message = getDefaultMessage(
      form.state.organizationRole,
      form.state.productRole
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.productRole, form.state.organizationRole])

  // Set initial emails if provided
  useEffect(() => {
    if (!givenEmails) return
    // Must set emails after initial form creation to enable form submission
    form.state.emails.replace(givenEmails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state])

  return (
    <Form testid={testid} onSubmit={handleSubmit} buttons={getFormButtons()}>
      {isDraftProduct && !onlyAdminRoles && (
        <DiscoAlert
          severity={"warning"}
          data-testid={"ProductVisibilityBanner.alert"}
          marginBottom={3}
          message={
            <>
              <DiscoText color={"groovy.yellow.700"} variant={"body-sm"}>
                {`This ${productLabelSingular} is in `}
                <DiscoText variant={"body-sm-600"} component={"span"}>
                  {"Draft"}
                </DiscoText>
                {`. ${
                  isPathway
                    ? ""
                    : `Only ${productRoleLabels.manager.plural} and ${productRoleLabels.instructor.plural} can be added.`
                } `}
                {canPublish && (
                  <>
                    {`To enable invites to ${productRoleLabels.member.plural}, `}
                    <PublishExperienceButton productId={product!.id}>
                      {(buttonProps) => (
                        <DiscoLink
                          {...buttonProps}
                          className={classes.publishExperinceLink}
                          data-testid={"ProductVisibilityBanner.alert.publish"}
                        >
                          <DiscoText variant={"body-sm-600"} component={"span"}>
                            {`Publish ${productLabelSingular}`}
                          </DiscoText>
                        </DiscoLink>
                      )}
                    </PublishExperienceButton>
                    {"."}
                  </>
                )}
              </DiscoText>
            </>
          }
        />
      )}
      {/* If only inviting non-member roles, hide the invite tab options that aren't supported */}
      {isDraftProduct || onlyAdminRoles ? (
        <InvitationFormEmailsField
          productKey={product}
          form={form}
          onlyAdminRoles={onlyAdminRoles}
        />
      ) : (
        <>
          <DiscoText variant={"body-sm"} marginBottom={0.5}>
            {"Send invites"}
          </DiscoText>
          <DiscoQueryParamTabs
            className={classes.tabs}
            tabs={[
              // Show the Invite Link for private communities
              ...ArrayUtils.spreadIf(
                {
                  label: "Invite Link",
                  params: { InviteTab: "invite-link" },
                  testid: "invite-link-tab",
                  content: <InvitationFormInviteLinkField />,
                },
                showInviteLink
              ),
              // Hide the link tab for private communities and invite-only experiences
              ...ArrayUtils.spreadIf(
                {
                  label: "Invite Link",
                  params: { InviteTab: "link" },
                  testid: "link-tab",
                  content: <InvitationFormCopyLinkField productKey={product} />,
                },
                product
                  ? product.tmpAllowExternalRegistrations ||
                      product.registrationAvailability !== "hidden"
                  : activeOrganization.visibility === "public"
              ),
              {
                label: "Email Invite",
                params: { InviteTab: "email" },
                testid: "email-tab",
                content: <InvitationFormEmailsField productKey={product} form={form} />,
              },
              ...ArrayUtils.spreadIf(
                {
                  label: "Bulk Invite",
                  params: { InviteTab: "csv" },
                  testid: "csv-tab",
                  content: <InvitationFormImportCSVBlockedField />,
                },
                !hasBulkInviteEntitlement
              ),
              ...ArrayUtils.spreadIf(
                {
                  label: "Bulk Invite",
                  params: { InviteTab: "csv" },
                  testid: "csv-tab",
                  content: (
                    <InvitationFormImportCSVField form={form} productKey={product} />
                  ),
                },
                hasBulkInviteEntitlement
              ),
            ]}
            testid={`${testid}.tabs`}
            tabVariant={"grey-track"}
          />
        </>
      )}
    </Form>
  )

  async function handleSubmit() {
    const valid = validateForm()
    if (!valid) return

    const input: CreateInvitationInput = {
      productId: form.state.productId,
      organizationId: form.state.organizationId,
      message: form.state.message,
      organizationRole: form.state.organizationRole,
      productRole: form.state.productRole,
      users: mapEmailsAndIdsToUsers(form.state),
    }

    // Options that are only valid for member invitations
    if ((input.productRole || input.organizationRole) === "member") {
      input.csvUrl = form.state.csvUrl
      input.skipsApplication = form.state.skipsApplication
      input.discountId = productId ? form.state.discountId : ""
      input.inviteToProductIds = form.state.inviteToExperience
        ? form.state.inviteToProductIds
        : []
    }

    const { didSave, response } = await form.submit(input, {
      // Add the new invitations to the connections
      connections: InvitationUtils.getConnections(activeOrganization.id, productId),
      updater: (store) => {
        const organizationRecord = store.get(activeOrganization.id)
        if (!organizationRecord) return
        ConnectionHandler.getConnections(
          organizationRecord,
          "CommunityGuestReportTable__guestMemberships"
        ).forEach((connection) => connection.invalidateRecord())

        // Skip if this is a community or member invite
        if (!productId || input.productRole === "member") return

        const productRecord = store.get(productId)
        if (productRecord) {
          // Invalidate the ExperienceAdminsList connection so it refetches
          ConnectionHandler.getConnections(
            productRecord,
            "ExperienceAdminsList__productMemberships"
          ).forEach((connection) => connection.invalidateRecord())

          // Invalidate product admin actions list so relevant ones can be refetched
          const actionsConnection = ConnectionHandler.getConnection(
            productRecord,
            "ProductAdminDashboardActions__productAdminActions"
          )
          actionsConnection?.invalidateRecord()
        }

        invalidateRoleAvatarStackProductRoles(store, activeOrganization.id)
      },
    })
    if (!didSave || !response) return

    onSubmit()
    displaySuccessToast({
      message: "Invitations sent!",
      testid: `${testid}.success-toast`,
    })
  }

  function getDefaultMessage(
    organizationRole?: OrganizationRole | null,
    productRole?: ProductRole | null
  ) {
    const hostName = authUser.fullName

    // Product invitation message
    if (product) {
      if (productRole === "member") {
        return `${hostName} from ${activeOrganization.name} has invited you to join ${product.name}.`
      }
      const roleLabel = productRoleLabels[productRole!].singular
      const role = `${wordStartsWithVowel(roleLabel) ? "an" : "a"} ${roleLabel}`
      return `${hostName} has invited you to be ${role} of ${product.name}.`
    }

    // Organization invitation message
    if (organizationRole === "member") {
      return `${hostName} has invited you to join the Community ${activeOrganization.name}.`
    }
    const roleLabel = organizationRoleLabels[organizationRole!].singular
    const role = `${wordStartsWithVowel(roleLabel) ? "an" : "a"} ${roleLabel}`
    return `${hostName} has invited you to be ${role} of the Community ${activeOrganization.name}.`
  }

  function validateForm() {
    form.removeErrors()
    if (queryParamState.InviteTab === "csv") {
      if (!form.state.csvUrl) {
        form.addError({ field: "csvUrl", message: "Please upload a CSV file" })
        return false
      }
    }

    // Validate emails
    if (queryParamState.InviteTab === "email") {
      // Remove any uploaded CSV file
      form.state.csvMetadata = {}
      if (!form.state.emails.length && !form.state.userIds.length) {
        form.addError({ field: "emails", message: "Please enter at least one email" })
        return false
      }

      if (!form.state.message) {
        form.addError({
          field: "message",
          message: "Please include a personalized message",
        })
        return false
      }
    }

    return true
  }

  async function handleValidateCSV() {
    setIsValidatingCsv(true)
    try {
      form.removeErrors()
      const {
        validateCSV: { rows, validRows, totalRows, errors },
      } = await validateCSV({
        csvUrl: form.state.csvUrl,
        productId: form.state.productId,
      })

      if (errors) {
        // Attach the validation errors to the form
        errors.forEach((e) => form.addError(e))
        return
      }

      form.state.csvMetadata!.data = rows
      form.state.csvStep = "preview"

      if (validRows && totalRows && totalRows - validRows > 0) {
        const invalidRows = totalRows - validRows
        form.addError({
          field: "invalidRows",
          message: `Found ${invalidRows} bad ${pluralize(
            "record",
            invalidRows
          )} in the file. Please upload a new file to continue and send invites.`,
        })
      }
    } finally {
      setIsValidatingCsv(false)
    }
  }

  function getFormButtons() {
    switch (queryParamState.InviteTab) {
      case "invite-link":
      case "link":
        return <DiscoButton onClick={onCancel}>{"Thanks, All Done!"}</DiscoButton>
      case "csv":
        return (
          <>
            <DiscoButton
              data-testid={`${testid}.cancel-button`}
              onClick={onCancel}
              color={"grey"}
              variant={"outlined"}
            >
              {"Cancel"}
            </DiscoButton>
            {form.state.csvStep === "preview" ? (
              <Form.SubmitButton form={form} testid={`${testid}.submit-button`}>
                {"Send Invites"}
              </Form.SubmitButton>
            ) : (
              <DiscoButton
                data-testid={`${testid}.csv-preview-button`}
                disabled={form.disabled || isValidatingCsv}
                shouldDisplaySpinner={isValidatingCsv}
                onClick={handleValidateCSV}
              >
                {"Preview Invites"}
              </DiscoButton>
            )}
          </>
        )
      default:
        return (
          <>
            <DiscoButton
              data-testid={`${testid}.cancel-button`}
              onClick={onCancel}
              color={"grey"}
              variant={"outlined"}
            >
              {"Cancel"}
            </DiscoButton>
            <Form.SubmitButton form={form} testid={`${testid}.submit-button`}>
              {"Send Invites"}
            </Form.SubmitButton>
          </>
        )
    }
  }

  function getDefaultProductRole(): ProductRole | null {
    if (!productId) return null
    // pathways only support member roles
    if (isPathway) return "member"
    if (defaultProductRole) return defaultProductRole
    if (!isDraftProduct && !onlyAdminRoles) return "member"
    return "instructor"
  }

  function getDefaultOrganizationRole(): OrganizationRole | null {
    if (productId) return null
    if (!onlyAdminRoles) return "member"
    return "admin"
  }
}

const useStyles = makeUseStyles((theme) => ({
  tabs: {
    marginBottom: theme.spacing(3),
  },
  publishExperinceLink: {
    textDecoration: "underline",
    color: theme.palette.groovy.yellow[700],
    fontWeight: "bold",
  },
  skeletonFooter: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
}))

export function CreateInvitationFormSkeleton() {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <>
      <DiscoTextSkeleton width={"100px"} />
      <Skeleton
        variant={"rect"}
        width={"250px"}
        height={"44px"}
        className={classes.tabs}
        style={{ borderRadius: theme.measure.borderRadius.big }}
      />
      <DiscoTextSkeleton width={"300px"} marginBottom={1} />
      <DiscoInputSkeleton />
      <DiscoTextSkeleton width={"150px"} marginTop={3} marginBottom={1} />
      <DiscoInputSkeleton height={120} />
      <div className={classes.skeletonFooter}>
        <DiscoButtonSkeleton width={"80px"} />
        <DiscoButtonSkeleton width={"120px"} />
      </div>
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(CreateInvitationForm),
  skeleton: CreateInvitationFormSkeleton,
})

// Map the emails string into the an array of user object, used for when the email tab is selected
export function mapEmailsAndIdsToUsers(state: {
  emails: string[]
  userIds: GlobalID[]
}): CreateInvitationInput["users"] {
  // Get all the unqiue emails from the emails string
  const emails = [
    ...new Set(
      state.emails
        .map((email) => email.trim())
        // Remove empty strings
        .filter(Boolean)
    ),
  ]
  const users: CreateInvitationInput["users"] = emails.map((email) => ({ email }))
  return users.concat(state.userIds.map((id) => ({ id })))
}
