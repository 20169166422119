import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import CopyIcon from "@/core/ui/iconsax/linear/copy.svg"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { InvitationFormCopyLinkFieldOrganizationQuery } from "@/invitation/create/form/fields/__generated__/InvitationFormCopyLinkFieldOrganizationQuery.graphql"
import { InvitationFormCopyLinkField__Product$key } from "@/invitation/create/form/fields/__generated__/InvitationFormCopyLinkField__Product.graphql"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import LinkPreview from "@components/link-preview/LinkPreview"
import { DiscoLink, DiscoText, DiscoTextButton } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { observer } from "mobx-react-lite"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props {
  productKey: InvitationFormCopyLinkField__Product$key | null
}

function InvitationFormCopyLinkField(props: Props) {
  const { productKey } = props
  const activeOrganization = useActiveOrganization()!

  const product = useFragment<InvitationFormCopyLinkField__Product$key>(
    graphql`
      fragment InvitationFormCopyLinkField__Product on Product {
        id
        type
        slug
        cover
        name
        description
        landingPage {
          metaImageUrl
          metaTitle
          metaDescription
        }
      }
    `,
    productKey
  )

  const { organization } = useLazyLoadQuery<InvitationFormCopyLinkFieldOrganizationQuery>(
    graphql`
      query InvitationFormCopyLinkFieldOrganizationQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            cover
            name
            description
            marketingPage {
              metaTitle
              metaDescription
              metaImageUrl
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    }
  )

  const copyToClipboard = useCopyToClipboard()

  const membersLabel = useLabel("admin_member")
  const productLabel = useAdminProductLabel(product?.type ?? "course")
  const classes = useStyles()
  const theme = useTheme()
  const inviteLink = product
    ? activeOrganization.primaryDomain +
      generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
        productSlug: product.slug,
      })
    : activeOrganization.primaryDomain + ROUTE_NAMES.COMMUNITY.JOIN.ROOT

  return (
    <>
      <DiscoTextButton
        testid={"InvitationFormCopyLinkField.copy-link-button"}
        onClick={() => copyToClipboard(inviteLink)}
        rightIcon={<CopyIcon />}
        classes={{
          root: classes.copyRoot,
          label: classes.copyLabel,
        }}
        color={theme.palette.text.primary}
      >
        {inviteLink}
      </DiscoTextButton>
      <LinkPreview
        image={
          product
            ? product.landingPage?.metaImageUrl || product.cover || defaultThumbnail
            : organization?.marketingPage?.metaImageUrl ||
              organization?.cover ||
              defaultThumbnail
        }
        title={
          product
            ? product.landingPage?.metaTitle || product.name
            : organization
            ? organization.marketingPage?.metaTitle || organization.name || ""
            : ""
        }
        description={
          product
            ? product.landingPage?.metaDescription || product.description
            : organization
            ? organization.marketingPage?.metaDescription || organization.description
            : null
        }
        url={inviteLink}
      />
      <div className={classes.linkContainer}>
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {"Copied link will go to the "}
          <DiscoLink
            to={inviteLink}
            data-testid={`InvitationFormCopyLinkField.registration-page.link`}
            target={"_blank"}
            className={classes.registrationPageLink}
          >
            {" Registration Page"}
          </DiscoLink>

          {` for this ${
            product ? productLabel.singular : `Community`
          } and prompt registration as a ${membersLabel.singular}.`}
        </DiscoText>
      </div>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  copyRoot: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
    width: "100%",
    "&:hover": {
      backgroundColor: theme.palette.groovy.neutral[100],
    },
  },
  copyLabel: {
    textAlign: "left",
    wordBreak: "break-all",
    lineHeight: 1.25,
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2.5),
  },
  registrationPageLink: {
    fontSize: "14px",
  },
}))

export default observer(InvitationFormCopyLinkField)
