import { DiscoText } from "@disco-ui"

function InvitationFormImportCSVBlockedField() {
  return (
    <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={1}>
      {`Contact sales@disco.co to enable bulk uploading for your account.`}
    </DiscoText>
  )
}

export default InvitationFormImportCSVBlockedField
