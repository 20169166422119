import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import HiddenForRoutes from "@/core/route/hidden-for-routes/HiddenForRoutes"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import {
  ONLY_PAGE_HEADER_VISIBLE_ROUTES,
  SIDEBARS_HIDDEN_ROUTES,
} from "@/main/page/AppPageLayout"
import LoginButton from "@/main/page/header/buttons/LoginButton"
import HeaderMobileLhs from "@/main/page/header/HeaderMobileLhs"
import HeaderMobileRhs from "@/main/page/header/HeaderMobileRhs"
import SideBarHeader from "@/organization/common/sidebar/SideBarHeader"
import useIsWebView from "@/product/util/hook/useIsWebView"
import styleIf from "@assets/style/util/styleIf"
import AuthenticatedUserDropdown from "@components/authenticated-user-dropdown/AuthenticatedUserDropdown"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { AppBar, Toolbar, useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Route, useLocation } from "react-router-dom"

interface AppHeaderLayoutProps {
  leftContainerContent?: React.ReactNode
  centerLeftContainerContent?: React.ReactNode
  centerContainerContent?: React.ReactNode
  rightContainerContent?: React.ReactNode
  additionalContent?: React.ReactNode
  className?: string
  classes?: Partial<{
    appBarRoot: string
    toolbarRoot: string
    pageHeaderLeftContainer: string
    pageHeaderCenterContainer: string
    pageHeaderRightContainer: string
  }>
  style?: React.CSSProperties
  showCommunityLogo?: boolean
}

const AppHeaderLayout: React.FC<AppHeaderLayoutProps> = ({
  leftContainerContent,
  centerLeftContainerContent,
  centerContainerContent,
  rightContainerContent,
  additionalContent,
  className,
  classes: propsClasses,
  style,
  showCommunityLogo,
}) => {
  const activeOrganization = useActiveOrganization()
  const activeProduct = useActiveProduct()
  const { authUser } = useAuthUser()
  const isMobile = useIsMobile()
  const isWebView = useIsWebView()
  const location = useLocation()
  const onGuestPage = location.pathname.includes("/g/")
  const theme = useTheme()

  const classes = useStyles({
    wrapToolbar: isMobile,
    leftContainerOrder: 0,
    centerContainerOrder: isMobile ? 2 : 1,
    rightContainerOrder: isMobile ? 1 : 2,
    hasActiveProduct: Boolean(activeProduct),
  })

  if (isWebView) return null

  return (
    <AppBar
      className={className}
      style={style}
      classes={{ root: classNames(classes.appBarRoot, propsClasses?.appBarRoot) }}
      elevation={0}
    >
      <Toolbar
        classes={{ root: classNames(classes.toolbarRoot, propsClasses?.toolbarRoot) }}
      >
        <div
          className={classNames(
            classes.pageHeaderLeftContainer,
            propsClasses?.pageHeaderLeftContainer
          )}
        >
          {/* on routes where we do not show side bars, allow users to return to community using logo */}
          {activeOrganization && (
            <Route
              exact
              path={showCommunityLogo ? undefined : ONLY_PAGE_HEADER_VISIBLE_ROUTES}
            >
              <SideBarHeader hideDashboardButton />
            </Route>
          )}
          {leftContainerContent || renderLeftContainerMobileContent()}
        </div>
        <div
          className={classNames(
            classes.pageHeaderCenterContainer,
            propsClasses?.pageHeaderCenterContainer
          )}
        >
          {centerLeftContainerContent || <PageHeaderPortalAnchor position={"left"} />}
          {centerContainerContent || <PageHeaderPortalAnchor position={"center"} />}
          <PageHeaderPortalAnchor position={"right"} />
        </div>
        <div
          className={classNames(
            classes.pageHeaderRightContainer,
            propsClasses?.pageHeaderRightContainer
          )}
        >
          {rightContainerContent || renderRightContainerContent()}
        </div>
      </Toolbar>
      {additionalContent || <PageHeaderPortalAnchor position={"bottom"} />}
    </AppBar>
  )

  function renderLeftContainerMobileContent() {
    if (!isMobile || !activeOrganization?.viewerMembership) return null
    return (
      <HiddenForRoutes exact path={SIDEBARS_HIDDEN_ROUTES}>
        <HeaderMobileLhs />
      </HiddenForRoutes>
    )
  }

  function renderRightContainerContent() {
    if (!authUser) return <LoginButton />
    if (onGuestPage) {
      return (
        <>
          <DiscoTag
            name={"Viewing as Guest"}
            backgroundColor={theme.palette.groovy.yellow[100]}
            testid={"AuthenticatedUserDropdown.guestTag"}
            marginRight={1}
          />
          <AuthenticatedUserDropdown logoutOnly />
        </>
      )
    }
    return <HeaderMobileRhs />
  }
}

type StyleProps = {
  wrapToolbar: boolean
  leftContainerOrder: number
  centerContainerOrder: number
  rightContainerOrder: number
  hasActiveProduct: boolean
}

const useStyles = makeUseStyles((theme) => ({
  appBarRoot: {
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.pageHeader,
    borderBottom: theme.palette.constants.borderSmall,
    paddingRight: "0px !important",
    backgroundColor: theme.palette.background.paper,
  },
  toolbarRoot: ({ wrapToolbar }: StyleProps) => ({
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    // Reduce height by appBarRoot's bottom border width
    minHeight: `calc(${theme.mixins.toolbar.minHeight}px - 1px)`,
    alignContent: "center",
    justifyContent: "space-between",
    flexWrap: wrapToolbar ? "wrap" : undefined,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  }),
  pageHeaderLeftContainer: ({ leftContainerOrder, wrapToolbar }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flext-start",
    flex: wrapToolbar ? "1" : undefined,
    order: leftContainerOrder,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 400,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 250,
    },
  }),
  pageHeaderCenterContainer: ({ centerContainerOrder, wrapToolbar }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    flex: "1",
    justifyContent: "space-between",
    order: centerContainerOrder,
    minWidth: 0,
    ...styleIf(wrapToolbar, { flexWrap: "wrap", flex: "1 0 100%" }),
  }),
  pageHeaderRightContainer: ({ rightContainerOrder, wrapToolbar }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: wrapToolbar ? "1" : undefined,
    order: rightContainerOrder,
  }),
}))

interface PortalStyleProps {
  leftPortal: boolean
}

const usePortalStyles = makeUseStyles({
  portal: ({ leftPortal }: PortalStyleProps) => ({
    minWidth: 0,
    ...styleIf(leftPortal, { flex: "1 1 0%" }),
  }),
})

export default AppHeaderLayout

interface PageHeaderPortalProps {
  position: "left" | "center" | "right" | "bottom"
  children: React.ReactNode
}

export function PageHeaderPortalAnchor(
  props: Pick<PageHeaderPortalProps, "position"> & { className?: string }
) {
  const classes = usePortalStyles({ leftPortal: props.position === "left" })
  const { position } = props
  useEffect(() => {
    // create event on mount to trigger PageHeaderPortal and update domEl
    const event = new CustomEvent("pageHeaderPortalAnchorMounted", {
      detail: { position },
    })
    window.dispatchEvent(event)
  }, [position])

  return (
    <div
      id={`page-header-${props.position}`}
      className={classNames(classes.portal, props.className)}
    />
  )
}

export function PageHeaderPortal(props: PageHeaderPortalProps) {
  const { children, position } = props
  const [el, setEl] = useState<Element | null>(null)

  useEffect(() => {
    function handleAnchorMounted(event: any) {
      if (event.detail.position === position) {
        const domEl = document.querySelector(`#page-header-${position}`)
        setEl(domEl)
      }
    }
    window.addEventListener("pageHeaderPortalAnchorMounted", handleAnchorMounted) // Listen to prevent setting el before it's rendered

    // Trigger a manual search in case the anchor is already in the DOM
    const domEl = document.querySelector(`#page-header-${position}`)
    if (domEl) {
      setEl(domEl)
    }

    return () => {
      window.removeEventListener("pageHeaderPortalAnchorMounted", handleAnchorMounted)
    }
  }, [position])

  if (!el) {
    return null
  }
  return ReactDOM.createPortal(children, el)
}
