/**
 * @generated SignedSource<<8cac1b29e235aa22f5496f3a5e648747>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
export type CreateInvitationModalQuery$variables = {
  productId: string;
};
export type CreateInvitationModalQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly type?: ProductType;
    readonly " $fragmentSpreads": FragmentRefs<"CreateInvitationFormFragment">;
  } | null;
};
export type CreateInvitationModalQuery = {
  variables: CreateInvitationModalQuery$variables;
  response: CreateInvitationModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerPermissions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInvitationModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CreateInvitationFormFragment"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateInvitationModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registrationAvailability",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tmpAllowExternalRegistrations",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cover",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LandingPage",
                "kind": "LinkedField",
                "name": "landingPage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metaImageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metaTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metaDescription",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registrationType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RegistrationPricing",
                "kind": "LinkedField",
                "name": "registrationPricing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "basePrice",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Product",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Event",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Comment",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Feed",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Post",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "ContentUsage",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "ChatChannel",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Dashboard",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "AssignmentSubmission",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "PathwayGroup",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8430507d9d97bdd76a01d24d1f54f1ff",
    "id": null,
    "metadata": {},
    "name": "CreateInvitationModalQuery",
    "operationKind": "query",
    "text": "query CreateInvitationModalQuery(\n  $productId: ID!\n) {\n  node(id: $productId) {\n    __typename\n    ... on Product {\n      id\n      type\n      ...CreateInvitationFormFragment\n    }\n    id\n  }\n}\n\nfragment CreateInvitationFormFragment on Product {\n  __typename\n  id\n  slug\n  name\n  type\n  status\n  registrationAvailability\n  tmpAllowExternalRegistrations\n  ...InvitationFormCopyLinkField__Product\n  ...InvitationFormEmailsField__Product\n  ...InvitationFormCSVPreview_productFragment\n  ...usePermissionsFragment\n}\n\nfragment InvitationFormCSVPreview_productFragment on Product {\n  slug\n  type\n}\n\nfragment InvitationFormCopyLinkField__Product on Product {\n  id\n  type\n  slug\n  cover\n  name\n  description\n  landingPage {\n    metaImageUrl\n    metaTitle\n    metaDescription\n    id\n  }\n}\n\nfragment InvitationFormEmailsField__Product on Product {\n  id\n  slug\n  type\n  status\n  ...InvitationFormMemberFieldsFragment\n  ...InvitationFormRoleSelectFragment\n}\n\nfragment InvitationFormMemberFieldsFragment on Product {\n  id\n  registrationType\n  registrationPricing {\n    basePrice\n  }\n  slug\n}\n\nfragment InvitationFormRoleSelectFragment on Product {\n  id\n  type\n  status\n  ...usePermissionsFragment\n}\n\nfragment usePermissionsFragment on Node {\n  __isNode: __typename\n  __typename\n  ... on Organization {\n    viewerPermissions\n  }\n  ... on Product {\n    viewerPermissions\n  }\n  ... on Event {\n    viewerPermissions\n  }\n  ... on Comment {\n    viewerPermissions\n  }\n  ... on Feed {\n    viewerPermissions\n  }\n  ... on Post {\n    viewerPermissions\n  }\n  ... on ContentUsage {\n    viewerPermissions\n  }\n  ... on ChatChannel {\n    viewerPermissions\n  }\n  ... on Dashboard {\n    viewerPermissions\n  }\n  ... on AssignmentSubmission {\n    viewerPermissions\n  }\n  ... on PathwayGroup {\n    viewerPermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b5849afeaaf5530f76604ca723d9ecd";

export default node;
