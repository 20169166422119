import LockIcon from "@/core/ui/images/empty-state-illustrations/lock.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import MetaDetails from "@/main/page/MetaDetails"
import { DiscoEmptyState } from "@disco-ui"
import { Theme, useMediaQuery } from "@material-ui/core"

function CommunityInaccessiblePage() {
  return (
    <MetaDetails>
      <CommunityInaccessibleContent />
    </MetaDetails>
  )
}

export function CommunityInaccessibleContent() {
  const classes = useStyles()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down("xs"))

  return (
    <div className={classes.root}>
      <DiscoEmptyState
        testid={"CommunityInaccessibleContent"}
        icon={<LockIcon />}
        title={"This community is no longer active."}
        subtitle={
          "This community has been paused or the trial has expired. Reach out to the community owner for further clarification."
        }
        variant={"page"}
        align={"left"}
        flow={isMobile ? "column" : "row-reverse"}
      />
    </div>
  )
}

const useStyles = makeUseStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
})

export default CommunityInaccessiblePage
